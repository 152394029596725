import styled from "styled-components";
import Helmet from "../assets/new_logo/Icon_green.png";
import { FlexContainer } from "./WrapperElements";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Header = () => {
  const { pathname } = window.location;
  const [scrolledPassedWH, setScrolledPassedWH] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setScrolledPassedWH(true);
      } else {
        setScrolledPassedWH(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Nav
      id="header"
      hasScrolledPassedWH={scrolledPassedWH}
      isHome={pathname === "/"}
    >
      <Link to="/" onClick={scrollToTop}>
        <a href="#header">
          <img src={Helmet} alt="Logo" style={{ width: "5%" }} />
        </a>
      </Link>

      <StyledFlexContainer>
        <UL>
          <StyledA href="./#contact">Kontakta oss</StyledA>
        </UL>
        <UL>
          <StyledA href="./#services">Utbildningar</StyledA>
        </UL>
        <UL>
          <StyledLink to="./gallery">Galleri</StyledLink>
        </UL>
        <UL>
          <StyledA href="./#schedule">Kommande utbildningar</StyledA>
        </UL>
        <UL>
          <StyledA href="./about-us" style={{ padding: 0 }}>
            Om oss
          </StyledA>
        </UL>
      </StyledFlexContainer>
    </Nav>
  );
};
export default Header;

const Nav = styled.nav<{ hasScrolledPassedWH?: boolean; isHome?: boolean }>`
  background: ${(props) =>
    !props.isHome || props.hasScrolledPassedWH
      ? "var(--color-gray)"
      : "transparent"};
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 40px;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;

  @media screen and (max-width: 950px) {
    background-color: transparent;
    padding: 0 10px;
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledA = styled.a`
  text-decoration: none;
  color: var(--color-black);
  padding-right: 20px;
  white-space: nowrap;

  :hover {
    color: var(--focus-clr);
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--color-black);
  padding-right: 20px;

  :hover {
    color: var(--focus-clr);
  }
`;
