import { FC } from "react";

type Props = {
  color?: string;
};

export const ArrowIcon: FC<Props> = ({ color = "#315C2B" }) => (
  <svg
    width="86"
    height="24"
    viewBox="0 0 86 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.0607 13.0607C85.6464 12.4749 85.6464 11.5251 85.0607 10.9393L75.5147 1.3934C74.9289 0.807612 73.9792 0.807612 73.3934 1.3934C72.8076 1.97918 72.8076 2.92893 73.3934 3.51472L81.8787 12L73.3934 20.4853C72.8076 21.0711 72.8076 22.0208 73.3934 22.6066C73.9792 23.1924 74.9289 23.1924 75.5147 22.6066L85.0607 13.0607ZM0 13.5H84V10.5H0V13.5Z"
      fill={color}
    />
  </svg>
);

export default ArrowIcon;
