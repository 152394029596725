import { FC } from "react";

type Props = {
  color?: string;
};

const PersonIcon: FC<Props> = ({ color = "#315C2B" }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7242 45.4484C35.2744 45.4484 45.4484 35.2744 45.4484 22.7242C45.4484 10.174 35.2744 0 22.7242 0C10.174 0 0 10.174 0 22.7242C0 35.2744 10.174 45.4484 22.7242 45.4484ZM22.7242 42.4644C33.6264 42.4644 42.4644 33.6264 42.4644 22.7242C42.4644 11.822 33.6264 2.98398 22.7242 2.98398C11.822 2.98398 2.98401 11.822 2.98401 22.7242C2.98401 33.6264 11.822 42.4644 22.7242 42.4644Z"
      fill={color}
    />
    <rect x="14" y="28" width="17" height="6" fill={color} />
    <circle cx="22.2279" cy="17.2279" r="5.22794" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5959 22.0739C15.9885 22.5051 14 24.7703 14 27.5C14 30.5376 16.4624 33 19.5 33H25.5C28.5376 33 31 30.5376 31 27.5C31 24.5465 28.6721 22.1368 25.751 22.0056C24.8122 22.904 23.5391 23.4559 22.1371 23.4559C20.771 23.4559 19.5273 22.9319 18.5959 22.0739Z"
      fill={color}
    />
  </svg>
);

export default PersonIcon;
