import Schaffolding from "../../assets/pexelsStallning.jpg";
import styled, { css } from "styled-components";
import { FlexContainer } from "../WrapperElements";
import { FC, useEffect, useState } from "react";
import { ServiceType, getServices } from "../../contentful/getServices";
import scaffolding from "../../assets/scaffolding.jpg";

export const Services: FC = () => {
  const [services, setServices] = useState<ServiceType[]>([]);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const res = await getServices();
        const sorted = res.sort((a, b) => {
          return a.educationName.localeCompare(b.educationName);
        });
        setServices(sorted);
      } catch (error) {
        console.log(error);
      }
    };

    if (!services.length) {
      fetchService();
    }
  }, [services]);

  return (
    <FlexContainer centeredJusitify>
      <StyledOurServices>
        <StyledBigPhotos>
          <BigPhoto />
        </StyledBigPhotos>

        <InfoWrapper>
          <h2 id="services">UTBILDNINGAR</h2>
          <CardsContainer>
            {services.map((service) => (
              <StyledLink
                key={service.educationName}
                href={service.pdf ? service.pdf : undefined}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card hasPdf={!!service.pdf}>
                  <StyledImage
                    src={service.image?.length ? service.image : scaffolding}
                    alt={service.educationName}
                  />
                  <CardTitle hasPdf={!!service.pdf}>
                    {service.educationName}
                  </CardTitle>
                </Card>
              </StyledLink>
            ))}
          </CardsContainer>

          <p>
            <i>
              Alla utbildare är ackrediterade för att registrera på ID06
              kompetensdatabas. Enligt de krav som ställs under 2023.
              <br />
              Vi håller öppna utbildningar på olika platser i Sverige.
              Lärarledda webbutbildningar och repetitionsutbildningar, även
              internutbildning på plats hos kund. Begär offert för mer
              information.
            </i>
          </p>
        </InfoWrapper>
      </StyledOurServices>
    </FlexContainer>
  );
};

const StyledOurServices = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 35px;
`;

const StyledBigPhotos = styled.div`
  display: flex;
  padding: 30px 0 30px 0;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const InfoWrapper = styled.div`
  padding: var(--main-padding);
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 24px;

  @media screen and (max-width: 950px) {
    grid-template-columns: 50% 50%;
    gap: 8px;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--color-black);
`;

const Card = styled.div<{ hasPdf: boolean }>`
  border-radius: 4px;
  height: 170px;
  display: flex;
  flex-direction: column;
  &:hover {
    ${(p) =>
      p.hasPdf &&
      css`
        transform: scale(1.04);
        transition: transform 0.2s ease-in-out;
        color: var(--color-primary-1);
        cursor: pointer;
      `};
  }

  @media screen and (max-width: 950px) {
    height: 140px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 8px;
`;

const CardTitle = styled.p<{ hasPdf: boolean }>`
  margin: 8px 0 0 0;
  line-height: 22px;
  flex: 1;
`;

const BigPhoto = styled.div`
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url(${Schaffolding});
  background-position: bottom;
  background-size: cover;
  height: 55vh;
  min-height: 500px;
  width: 100%;
`;
