import styled from "styled-components";

import React, { useEffect, useMemo, useState } from "react";
import { getSchedule, ScheduleType } from "../../contentful/getSchedule";

const ScheduleMobile: React.FC = () => {
  const [schedule, setSchedule] = useState<ScheduleType[]>([]);

  const [activeLocation, setActiveLocation] = useState("");

  const currentScheduleItems = useMemo(
    () =>
      schedule.find((item) => item.location === activeLocation)
        ?.weeklyEducations || [],
    [schedule, activeLocation]
  );

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const res = await getSchedule();

        const sorted = res.sort((a, b) => {
          return a.location.localeCompare(b.location);
        });
        setSchedule(sorted);
        const defaultLocation = sorted.map((item) => item.location)[0];
        if (defaultLocation) setActiveLocation(defaultLocation);
      } catch (error) {
        console.log(error);
      }
    };

    if (!schedule.length) {
      fetchSchedule();
    }
  }, [schedule]);

  if (!schedule.length) {
    return null;
  }

  return (
    <Container>
      <Title>KOMMANDE UTBILDNINGAR</Title>

      <LocationTabs>
        <TabsContainer>
          {schedule.map(({ location }, index) => (
            <TabButton
              key={location}
              $active={activeLocation === location}
              onClick={() => setActiveLocation(location)}
            >
              {location}
            </TabButton>
          ))}
        </TabsContainer>
      </LocationTabs>

      <ScheduleList>
        {/* {schedule.map(({ weeklyEducations }, index) => ( */}
        <>
          {currentScheduleItems.map((education, index) => (
            <ScheduleItem key={index}>
              {/* <IconContainer>
                <Calendar className="w-6 h-6" />
              </IconContainer> */}
              <ItemContent>
                <ItemTitle>{education.service}</ItemTitle>
                <ItemTime>
                  {education.day} {education.date} {education.time}
                </ItemTime>
              </ItemContent>
            </ScheduleItem>
          ))}
        </>
        {/* ))} */}
      </ScheduleList>
    </Container>
  );
};

export default ScheduleMobile;

export const Container = styled.div`
  max-width: 28rem;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f9fafb;
  min-height: 100vh;
`;

export const LocationTabs = styled.div`
  background-color: #f0f4f0;
  border-radius: 20px;
  padding: 0.25rem;
  margin-bottom: 2rem;
`;

export const TabsContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
`;

interface TabButtonProps {
  $active: boolean;
}

export const TabButton = styled.button<TabButtonProps>`
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s;
  color: ${(props) => (props.$active ? "#000" : "#5c8b5c")};
  background-color: ${(props) => (props.$active ? "#fff" : "transparent")};
  box-shadow: ${(props) =>
    props.$active ? "0 1px 3px rgba(0,0,0,0.1)" : "none"};
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const ScheduleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ScheduleItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  background-color: #f0f4f0;
  padding: 1rem;
  border-radius: 0.5rem;
`;

export const IconContainer = styled.div`
  padding: 0.75rem;
  background-color: white;
  border-radius: 0.5rem;
  color: #4b5563;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemTitle = styled.h3`
  font-weight: 600;
  font-size: 1.125rem;
`;

export const ItemTime = styled.p`
  color: #5c8b5c;
`;
