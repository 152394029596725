// import { createClient } from "contentful";
import dayjs from "dayjs";
import "dayjs/locale/sv";
dayjs.locale("sv");
const contentful = require("contentful");
// const contentful = require("contentful/contentful.node");

export const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});
