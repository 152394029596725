import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import scaffolding from "../assets/scaffolding.jpg";

function AboutUs() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Root>
      <PresentationWrapper>
        <ImageContainer>
          <StyledImage src={scaffolding} alt="Logo" />
        </ImageContainer>
        <Presentation>
          <h1>OM OSS</h1>
          <p>
            von Braun Utbildning grundades 2020 av Lotta von Braun, som har över
            ett decennium av erfarenhet inom utbildningsbranschen för bygg,
            anläggning och industri. Vi erbjuder professionella utbildningar som
            stärker både individen och företaget, med särskilt fokus på säkerhet
            och praktiska kunskaper. Med Lotta vid rodret strävar vi efter att
            rusta våra deltagare för en trygg och effektiv arbetsmiljö, oavsett
            var de befinner sig.
          </p>
        </Presentation>
      </PresentationWrapper>
      <VideoWrapper>
        <iframe
          src="https://www.youtube.com/embed/Wo97Ed59DmA?controls=0&modestbranding=1&showinfo=0&rel=0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={{ border: "none", borderRadius: "12px" }}
        ></iframe>
      </VideoWrapper>
    </Root>
  );
}
export default AboutUs;

const Root = styled.div`
  background-color: var(--color-white);
  padding: var(--main-padding);
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 950px) {
    margin-top: 30px;
  }
`;

const VideoWrapper = styled.div`
  width: 738px;
  height: 415px;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const PresentationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 738px;
  gap: 34px;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Presentation = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    margin-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  flex: 1;
  border-radius: 12px;
`;
