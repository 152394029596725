import { client } from ".";

export type ServiceType = {
  educationName: string;
  image?: string;
  pdf?: string;
};

export const getServices = async (): Promise<ServiceType[]> => {
  const response = await client.getEntries({
    content_type: "education",
  });

  return response.items.map((item: any) => {
    return {
      educationName: item.fields.educationName,
      image:
        item?.fields?.image?.fields?.file?.url?.replace("//", "https://") ?? "",
      pdf:
        item?.fields?.pdf?.fields?.file?.url?.replace("//", "https://") ?? "",
    };
  });
};
