import Gallery1 from "../../assets/gallery/gallery1.webp";
import Gallery2 from "../../assets/gallery/gallery2.webp";
import Gallery3 from "../../assets/gallery/gallery3.webp";
import Gallery4 from "../../assets/gallery/gallery4.webp";
import Gallery5 from "../../assets/gallery/gallery5.webp";
import Gallery6 from "../../assets/gallery/gallery6.webp";
import Gallery7 from "../../assets/gallery/gallery7.webp";
import Gallery8 from "../../assets/gallery/gallery8.webp";
import Gallery9 from "../../assets/gallery/gallery9.webp";
import Gallery10 from "../../assets/gallery/gallery10.webp";
import Gallery11 from "../../assets/gallery/gallery11.webp";
import Gallery12 from "../../assets/gallery/gallery12.webp";
import Gallery13 from "../../assets/gallery/gallery13.webp";
import Gallery14 from "../../assets/gallery/gallery14.webp";
import Gallery15 from "../../assets/gallery/gallery15.webp";
import Gallery16 from "../../assets/gallery/gallery16.webp";
import Gallery17 from "../../assets/gallery/gallery17.webp";
import Gallery18 from "../../assets/gallery/gallery18.webp";
import Gallery19 from "../../assets/gallery/gallery19.webp";
import Gallery20 from "../../assets/gallery/gallery20.webp";
import Gallery21 from "../../assets/gallery/gallery21.webp";
import Gallery22 from "../../assets/gallery/gallery22.webp";
import Gallery23 from "../../assets/gallery/gallery23.webp";
// import Gallery24 from "../../assets/gallery/gallery24.webp";
import Gallery25 from "../../assets/gallery/gallery25.webp";
// import Gallery26 from "../../assets/gallery/gallery26.webp";
import Gallery27 from "../../assets/gallery/gallery27.webp";
import Gallery28 from "../../assets/gallery/gallery28.webp";
import Gallery29 from "../../assets/gallery/gallery29.webp";
import Gallery30 from "../../assets/gallery/gallery30.webp";
import Gallery33 from "../../assets/gallery/gallery33.webp";
import Gallery34 from "../../assets/gallery/gallery34.webp";
import Gallery35 from "../../assets/gallery/gallery35.webp";

export interface Gallery {
  image: any;
  alt: string;
}

const gallerys: Gallery[] = [
  {
    image: Gallery1,
    alt: "",
  },
  {
    image: Gallery2,
    alt: "",
  },
  {
    image: Gallery3,
    alt: "",
  },
  {
    image: Gallery4,
    alt: "",
  },
  {
    image: Gallery5,
    alt: "",
  },
  {
    image: Gallery6,
    alt: "",
  },
  {
    image: Gallery7,
    alt: "",
  },
  {
    image: Gallery8,
    alt: "",
  },
  {
    image: Gallery9,
    alt: "",
  },
  {
    image: Gallery10,
    alt: "",
  },
  {
    image: Gallery11,
    alt: "",
  },
  {
    image: Gallery12,
    alt: "",
  },
  {
    image: Gallery13,
    alt: "",
  },
  {
    image: Gallery14,
    alt: "",
  },
  {
    image: Gallery15,
    alt: "",
  },
  {
    image: Gallery16,
    alt: "",
  },
  {
    image: Gallery17,
    alt: "",
  },
  {
    image: Gallery18,
    alt: "",
  },
  {
    image: Gallery19,
    alt: "",
  },
  {
    image: Gallery20,
    alt: "",
  },
  {
    image: Gallery21,
    alt: "",
  },
  {
    image: Gallery22,
    alt: "",
  },
  {
    image: Gallery23,
    alt: "",
  },
  // {
  //   image: Gallery24,
  //   alt: "",
  // },
  {
    image: Gallery25,
    alt: "",
  },
  // {
  //   image: Gallery26,
  //   alt: "",
  // },
  {
    image: Gallery27,
    alt: "",
  },
  {
    image: Gallery28,
    alt: "",
  },
  {
    image: Gallery29,
    alt: "",
  },
  {
    image: Gallery30,
    alt: "",
  },
  {
    image: Gallery33,
    alt: "",
  },
  {
    image: Gallery34,
    alt: "Hjullastare",
  },
  {
    image: Gallery35,
    alt: "",
  },
];
export default gallerys;
