import { useMediaQuery } from "@material-ui/core";
import Contact from "./main/contact";
import ImageCarousel from "./main/imageCarousel";
import { Schedule } from "./main/schedule";
import ScheduleMobile from "./main/scheduleMobile";
import { Services } from "./main/services";

const MainPage = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <>
      <Contact />
      <Services />
      <ImageCarousel />
      {isMobile ? <ScheduleMobile /> : <Schedule />}
    </>
  );
};

export default MainPage;
