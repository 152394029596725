import styled from "styled-components";
import Scaffolding from "../assets/scaffolding.jpg";
// import LogoWeb from "../assets/new_logo/Logotype_green.png";
// import LogoWeb from "../assets/new_logo/Logotype_white.png";
import LogoWeb from "../assets/new_logo/Logotype_inverted.png";

function Header() {
  return (
    <StyledHeader id="header">
      <LogoContainer>
        <img
          src={LogoWeb}
          alt="Logo"
          width="50%"
          style={{ objectFit: "contain" }}
        />
      </LogoContainer>
    </StyledHeader>
  );
}
export default Header;

const StyledHeader = styled.header`
  background-image: url(${Scaffolding});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  width: 100%;
  height: 100vh;

  position: fixed;
  z-index: -1;
  display: flex;
  justify-content: end;
`;

const LogoContainer = styled.div`
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;
