import styled from "styled-components";
import LogoWeb from "../assets/new_logo/Logotype_green.png";

function Footer() {
  return (
    <StyledFooter>
      <StyledMainContent>
        <div>
          <img
            src={LogoWeb}
            alt="Logo"
            width="20%"
            style={{ objectFit: "contain" }}
          />
        </div>
        <p>
          von Braun Utbildning AB <br />
          <StyledA href="mailto:info@vonbraunutbildning.se">
            info@vonbraunutbildning.se
          </StyledA>
          <br />
          <StyledA href="tel:+46739519360">+46 (0)73-951 93 60</StyledA>
        </p>
      </StyledMainContent>

      <StyledP>
        ©2024 von Braun Utbildning AB |<span>&nbsp;</span>
        <StyledA
          href="https://feliciavonbraun.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          By Felicia von Braun
        </StyledA>
      </StyledP>
    </StyledFooter>
  );
}
export default Footer;

const StyledFooter = styled.footer`
  background-color: var(--color-gray);
  padding: 28px var(--padding-horizontal);
  height: 250px;

  @media screen and (max-width: 950px) {
    height: auto;
  }
`;

const StyledMainContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const StyledA = styled.a`
  color: black;
  text-decoration: none;

  :visited {
    color: black;
  }

  :hover {
    color: var(--focus-clr);
  }
`;

const StyledP = styled.p`
  margin: 0;
  font-size: x-small;
  display: flex;
  justify-content: center;
`;
